<template>
  <section>
    <h2 class="uppercase pl-4 lg:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Projekte
    </h2>
    <article>
      <h3 class="text-6xl py-10 md:pb-10 text-center" style="color: #f27405">
        Projekte
      </h3>
      <div class="py-6">
        <CoolLightBox :items="items" :index="index" @close="index = null">
        </CoolLightBox>
        <figure class="flex flex-wrap justify-center">
          <img
            class="image"
            v-for="(image, imageIndex) in items"
            :key="imageIndex"
            @click="index = imageIndex"
            :src="image.src"
            :alt="image.alt"
            :style="{ backgroundImage: 'url(' + image.thumb + ')' }"
          />
        </figure>
      </div>
    </article>
  </section>
</template>

<script>
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  components: {
    CoolLightBox,
  },
  data: function () {
    return {
      items: [
        {
          src: require("../assets/img/projekte/projekt38-1.webp"),
          alt: "terrassendach berlin restaurant",
        },
        {
          src: require("../assets/img/projekte/projekt39.webp"),
          alt: "rolladen berlin",
        },
        {
          src: require("../assets/img/projekte/projekt40.webp"),
          alt: "markise berlin blau automatisch",
        },
        {
          thumb: require("../assets/img/projekte/thumb2.webp"),
          src: require("../assets/img/projekte/projekt-video1.mp4"),
        },
        {
          src: require("../assets/img/projekte/projekt41.webp"),
          alt: "markise einfach blau berlin",
        },
        {
          src: require("../assets/img/projekte/projekt42.webp"),
          alt: "berlin rolladen montage",
        },
        {
          src: require("../assets/img/projekte/projekt43.webp"),
          alt: "berlin fenster montage",
        },
        {
          src: require("../assets/img/projekte/projekt44.webp"),
          alt: "berlin fenster montage",
        },
        {
          src: require("../assets/img/projekte/projekt45.webp"),
          alt: "terrassendach heroal offen",
        },
        {
          src: require("../assets/img/projekte/projekt46.webp"),
          alt: "terrassendach heroal sonnenschutz",
        },
        {
          src: require("../assets/img/projekte/49.webp"),
          alt: "berlin meisterbetrieb rolladen markisen",
        },
        {
          src: require("../assets/img/projekte/projekt47.webp"),
          alt: "markise berlin sommer",
        },
        {
          src: require("../assets/img/projekte/projekt48.webp"),
          alt: "markise berlin sommerhaus",
        },
        {
          src: require("../assets/img/projekte/projekt50.webp"),
          alt: "rolladen montage",
        },
        {
          src: require("../assets/img/projekte/projekt51.webp"),
          alt: "rolladen installation",
        },
        {
          src: require("../assets/img/projekte/projekt53.webp"),
          alt: "berlin terrassendach pergola sunrain q",
        },
        {
          src: require("../assets/img/projekte/projekt54.webp"),
          alt: "berlin terrassendach pergola sunrain q montage",
        },
        {
          src: require("../assets/img/projekte/projekt55.webp"),
          alt: "rolladen montage",
        },
        {
          src: require("../assets/img/projekte/48.webp"),
          alt: "wall museum markisen installation",
        },
        {
          src: require("../assets/img/projekte/projekt1.webp"),
          alt: "estrich",
        },
        {
          src: require("../assets/img/projekte/solar1.webp"),
          alt: "heroal vorbaurolladen berlin",
        },
        {
          src: require("../assets/img/projekte/projekt2.webp"),
          alt: "heroal vorbaurolladen berlin",
        },
        {
          src: require("../assets/img/projekte/projekt3.webp"),
          alt: "zipscreen berlin",
        },
        {
          src: require("../assets/img/projekte/projekt4.webp"),
          alt: "vorbaurolladen",
        },
        {
          src: require("../assets/img/projekte/projekt5.webp"),
          alt: "vorbaurolladen installation",
        },
        {
          src: require("../assets/img/projekte/projekt6.webp"),
          alt: "vorbaurolladen",
        },
        {
          src: require("../assets/img/projekte/projekt7.webp"),
          alt: "fensterschutz",
        },
        {
          src: require("../assets/img/projekte/projekt8.webp"),
          alt: "rolladen",
        },
        {
          src: require("../assets/img/projekte/projekt9.webp"),
          alt: "rolladen berlin",
        },
        {
          src: require("../assets/img/projekte/projekt10.webp"),
          alt: "vorbaurolladen installation",
        },
        {
          src: require("../assets/img/projekte/projekt21.webp"),
          alt: "estrich projekt",
        },
        {
          src: require("../assets/img/projekte/projekt12.webp"),
          alt: "vorbaurolladen",
        },
        {
          src: require("../assets/img/projekte/projekt14.webp"),
          alt: "markise ausgefahren",
        },
        {
          src: require("../assets/img/projekte/projekt15.webp"),
          alt: "fensterrolladen",
        },
        {
          src: require("../assets/img/projekte/projekt16.webp"),
          alt: "fensterrolladen montage",
        },
        {
          src: require("../assets/img/projekte/projekt17.webp"),
          alt: "fensterrolladen montatge mit kran",
        },
        {
          src: require("../assets/img/projekte/projekt18.webp"),
          alt: "fensterrolladen montatge mit kran",
        },
        {
          src: require("../assets/img/projekte/projekt19.webp"),
          alt: "elektrik kasten",
        },
        {
          src: require("../assets/img/projekte/projekt20.webp"),
          alt: "estrich",
        },
        {
          src: require("../assets/img/projekte/projekt21.webp"),
          alt: "estrich",
        },
        {
          src: require("../assets/img/projekte/projekt22.webp"),
          alt: "estrich im keller",
        },
        {
          src: require("../assets/img/projekte/projekt23.webp"),
          alt: "rolladen in der kueche",
        },
        {
          src: require("../assets/img/projekte/projekt24.webp"),
          alt: "estrich auslage",
        },
        {
          src: require("../assets/img/projekte/projekt25.webp"),
          alt: "estrich verarbeitung",
        },
        {
          src: require("../assets/img/projekte/projekt26.webp"),
          alt: "estrich fertigstellung",
        },
        {
          src: require("../assets/img/projekte/projekt27.webp"),
          alt: "rollladen buero",
        },
        {
          src: require("../assets/img/projekte/projekt28.webp"),
          alt: "rolladen buero",
        },
        {
          src: require("../assets/img/projekte/projekt30.webp"),
          alt: "rolladen am dach",
        },
        {
          src: require("../assets/img/projekte/projekt31.webp"),
          alt: "rolladen montage am dach mit kran",
        },
        {
          src: require("../assets/img/projekte/projekt32.webp"),
          alt: "grossprojekt rolladen montage",
        },
        {
          src: require("../assets/img/projekte/projekt33.webp"),
        },
        {
          src: require("../assets/img/projekte/projekt34.webp"),
        },
        {
          src: require("../assets/img/projekte/projekt35.webp"),
        },
        {
          src: require("../assets/img/projekte/projekt36.webp"),
        },
        {
          src: require("../assets/img/projekte/projekt37.webp"),
        },
      ],
      index: null,
    };
  },
};
</script>
<style scoped>
.image {
  width: 400px;
  height: 300px;
  margin: 20px;
}
</style>
